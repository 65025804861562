<template>
  <v-container v-if="itemData" class="article pt-0 px-0">
    <v-img
      :src="require(`@/assets/${itemData.img}`)"
      gradient="to bottom, rgba(0,0,0,.4), rgba(0,0,0,0)"
      height="35vh"
    >
      <router-link to="/">
        <v-subheader> Back to Home </v-subheader>
      </router-link>
    </v-img>

    <v-sheet class="py-5 px-3">
      <h1 class="main_title font-weight-bold mb-4" v-text="itemData.title" />
      <v-row>
        <v-col
          cols="12"
          md="8"
          class="grey--text text--darken-2 font-weight-light"
        >
          <div v-for="(item, index) in itemData.content" :key="index">
            <p class="sentence" v-if="item.type == 'p'">{{ item.content }}</p>
            <div v-else-if="item.type == 'link'">
              <a :href="item.link" target="_blank">{{ item.content }}</a>
            </div>
            <div v-else-if="item.type == 'git'">
              <v-card
                class="mt-2 mb-2"
                elevation="2"
                max-width="300"
                outlined
                @click="newLink(item.link)"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6 mb-1">
                      Github
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.content
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </div>
        </v-col>
        <v-col v-if="itemData.needAltImg" cols="12" md="4" class="mt-5">
          <v-card class="mx-auto" elevation="24" max-width="400">
            <v-img :src="require(`@/assets/${itemData.altImg}`)" />
          </v-card>
        </v-col>
      </v-row>
      <v-row tag="p">
        <v-col
          cols="12"
          class="mt-5 pt-4 gray mx-0"
          justify="space-between"
          align="center"
        >
          <div class="d-flex" align="center">
            <span class="pl-1 pr-3 subheading"> developers </span>
            <v-btn icon>
              <v-icon>mdi-discord</v-icon>
            </v-btn>

            <v-btn icon>
              <v-icon>mdi-reddit</v-icon>
            </v-btn>

            <v-btn icon>
              <v-icon>mdi-twitter</v-icon>
            </v-btn>

            <v-btn icon>
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
// Utilities
import { mapGetters, mapState } from "vuex";

export default {
  name: "Article",

  computed: {
    ...mapGetters("home", ["parsedArticles"]),
    ...mapGetters("codes", ["parsedCodes"]),
    ...mapState("route", ["params"]),
    itemData() {
      if (window.location.href.includes("store/codes")) {
        return this.parsedCodes.find((item) => item.id == this.params.id);
      } else {
        return this.parsedArticles.find((item) => item.id == this.params.id);
      }
    },
  },
  methods: {
    newLink(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss">
$link_color: rgb(31, 170, 170);
.article {
  p {
    line-height: 1.8;
    padding: 0 6px 10px;
    margin: 0;
    color: rgb(236, 236, 236);
  }
  a {
    line-height: 2.5;
    padding: 0 6px 10px;
    color: $link_color !important;
  }
}

.main_title {
  font-size: 3.2rem;
}
.sub_title {
  font-size: 0.95rem;
}
.sentence {
  font-size: 1.1rem;
}

@media (max-width: 600px) {
  .main_title {
    font-size: 2rem;
  }
  .sub_title {
    font-size: 0.9rem;
  }
  .sentence {
    font-size: 1rem;
  }
}
</style>
